import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Layout = ({ children }) => {
  return (
    <div className="is-white-bg is-white pad-10">
      <main>{children}</main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
